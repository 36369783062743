<template>
    <el-dialog class="dialog_div" :append-to-body="true" title="编辑课程内容" :visible.sync="ModifyCourseContentDialogVisible" :close-on-click-modal="false" width="45%">
        <div>
        <el-form :rules="CourseContentFormRules" :model="CourseContentForm" ref="CourseContentFormRef" label-width="80px"  v-loading="Loading">
            <el-form-item label="课程：" >
                <el-cascader
                    class="addcoursecontent_item" 
                    placeholder="请选择课程"
                    v-model="CourseContentForm.SelectIDs"
                    collapse-tags
                    :options="CourseCFAndCourse"
                    :props="CascaderProps"
                    clearable>
                </el-cascader>
            </el-form-item>
            <el-form-item label="名称" prop="Name">
                <el-input class="course_Input" v-model="CourseContentForm.Name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="课程目标" prop="Evolution">
                <el-input type="textarea"  :autosize="{ minRows: 15, maxRows: 20}" placeholder="请输入内容" 
                v-model="CourseContentForm.Evolution" autocomplete="off"
                maxlength="500"
                show-word-limit></el-input>
            </el-form-item>
        </el-form>
          
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="CloseDialog">取消</el-button>
            <el-button type="primary" @click="SubmitCourseContent" :disabled="Loading">确认</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { PutCourseContentInfo } from '@/assets/js/api/courseapi' 
import { GetCourseClassFicationAndCourse } from '@/assets/js/api/courseclassficationapi' 
export default {
    components:{
 
    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        coursecontentinfo:Object
    },
    computed:{
        ModifyCourseContentDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        },
    },
    data(){
        return{
            CourseContentForm:{
                ID:0,
                CourseID:0,
                Name:'',
                Evolution:'',
                CourseClassFicationID:0,
                SelectIDs:[],
                CourseIDs:[],
            },
            CourseCFAndCourse:[],
            CascaderProps:{
                value:'id',
                label:'name',
                children:'children',
                multiple:true,
                checkStrictly: false
            },
            CourseContentFormRules:{
                Name: [
                    { required: true, message: '请填写名称', trigger: 'blur' }
                ],
                SelectIDs: [
                    { required: true, message: '请填写选择课程', trigger: 'change' }
                ]
            },
            Loading:false
        }
    },
    watch:{
        ModifyCourseContentDialogVisible:function (newVisible) {
            if(newVisible){
                this.CourseClassFicationAndCourse()
                this.CourseContentForm.ID = this.coursecontentinfo.id
                this.CourseContentForm.Name = this.coursecontentinfo.name
                this.CourseContentForm.Evolution = this.coursecontentinfo.evolution
                this.CourseContentForm.SelectIDs = this.coursecontentinfo.selected
            }
        }
    },
    created(){
        
    },
    methods:{
        async SubmitCourseContent(){
            this.$refs.CourseContentFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            if(this.CourseContentForm.Name == this.coursecontentinfo.name &&
                this.CourseContentForm.Evolution == this.coursecontentinfo.evolution &&
                this.CourseContentForm.SelectIDs == this.coursecontentinfo.selected){
                return this.$message.warning('数据没有修改，不需要提交');
            }
            this.CourseContentForm.CourseIDs = []
            for(var i = 0; i < this.CourseContentForm.SelectIDs.length; i++ ){
                this.CourseContentForm.CourseIDs.push(this.CourseContentForm.SelectIDs[i][1])
            }
            this.Loading = true
            var ret = await PutCourseContentInfo(this.CourseContentForm)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                ret.data.id = this.CourseContentForm.ID
                this.$message.success('修改课程内容成功')
                this.$refs.CourseContentFormRef.resetFields();
                this.$emit("ChangeModifyCourseContentInfo", ret.data)
                this.ModifyCourseContentDialogVisible = false
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        CloseDialog(){
            this.$refs.CourseContentFormRef.resetFields();
            this.Loading = false
            this.ModifyCourseContentDialogVisible = false
        },
        async CourseClassFicationAndCourse(){
            this.Loading = true
            var ret = await GetCourseClassFicationAndCourse()
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.CourseCFAndCourse = ret.data
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
    }
}
</script>

<style scoped lang="less">
    .course_Input{
        width: 500px;
        display: flex;
    }
    .dialog_div{
        text-align: center
        }
    .addcoursecontent_item{
        width: 50%;
        display: flex;
    }
</style>
