import axios from 'axios';

//查询课程内容
export async function GetCourseContentList(queryinfo){
    var res = await axios.get('Course/v1/CourseContentsQuery', {params: queryinfo})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }
  
  //提交新建课程内容
  export async function PostCourseContentInfo(CourseContent){
    var res = await axios.post('Course/v1/CourseContent', CourseContent)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }
  
  
  //提交修改课程内容
  export async function PutCourseContentInfo(CourseContent){
    var res = await axios.put('Course/v1/ModifyCourseContent', CourseContent)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }
  
  //根据ID获取课程内容
  export async function GetCourseContentByID(id){
    var res = await axios.get('Course/v1/CourseContentByID', { params: { ID:id}})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }
  
  //根据ID、名称获取课程内容
  export async function GetCourseContentByNameAndCourseID(name, courseid){
    var res = await axios.get('Course/v1/CourseContentByNameAndCourseID', { params: { Name:name, CourseID: courseid}})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }
  
  
  //根据课程ID查询课程内容
  export async function GetCourseContentsQueryByCourseID(queryinfo){
    var res = await axios.get('Course/v1/CourseContentsQueryByCourseID', {params: queryinfo})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }
  
  
  //修改课程内容的链接状态
  export async function PutCourseContentLinkInfo(courseinfo){
    var res = await axios.put('Course/v1/ModifyCourseContentLinked', courseinfo)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }
  
  //根据课程ID查询不属于该ID的课程内容
  export async function GetCourseContentsQueryNotInCourseID(queryinfo){
    var res = await axios.get('Course/v1/CourseContentsQueryNotInCourseID', {params: queryinfo})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }
  
  //删除课程内容
  export async function DeleteCourseContent(id){
    var res = await axios.delete('Course/v1/CourseContent',{ params: { id:id}})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }


//新建课程
export async function PostCourseInfo(courseinfo){
    var res = await axios.post('Course/v1/Course', courseinfo)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }
  
  //获取课程列表
  export async function GetQueryCourseList(queryinfo){
    var res = await axios.get('Course/v1/CoursesQuery', {params: queryinfo})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }
  
  //修改课程信息
  export async function PutCourseInfo(courseinfo){
    var res = await axios.put('Course/v1/Course', courseinfo)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }

